// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\envs\\jbr-five\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\envs\\jbr-five\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-impressum-js": () => import("C:\\envs\\jbr-five\\src\\pages\\impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("C:\\envs\\jbr-five\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("C:\\envs\\jbr-five\\src\\pages\\news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

